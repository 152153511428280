/** @jsx jsx */
import * as React from "react"
import { jsx, Flex } from "theme-ui"

type ColumnsProps = {
	pcReverse?: boolean
	mb?: any
	csx?: any
	children: React.ReactNode
}

const Columns: React.FC<ColumnsProps> = ({
	pcReverse = false,
	mb = `48px`,
	csx,
	children,
}) => (
	<Flex sx={{
		flexDirection: [
			`column`,
			pcReverse ? `row-reverse` : `row`,
		],
		mx: `auto`,
		mb: [`48px`,`48px`,5],
		maxWidth: [`480px`,`100%`],
		">*": {
			width: `100%`,
			"&:not(:last-of-type)": {
				mr: pcReverse ? `unset` : [0,5],
				mb: [mb,0],
			},
			"&:not(:first-of-type)": {
				mr: pcReverse ? [0,5] : `unset`,
			},
		},
		...csx,
	}}>
		{children}
	</Flex>
)

export default Columns
