import { graphql, useStaticQuery } from "gatsby"

type useImgStatic = {
	allFile: {
		nodes: {
			relativeDirectory: string
			name: string
		}[]
	}
}

const useStaticImages = (directory) => {
	const data = useStaticQuery<useImgStatic>(graphql`
		{
			allFile(filter: {sourceInstanceName: {eq: "staticImages"}}) {
				nodes {
					relativeDirectory
					name
				}
			}
		}
	`)
	return directory == null ? data.allFile.nodes :
		data.allFile.nodes.filter(e=>e.relativeDirectory == directory)
}

export default useStaticImages
