import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import useImages from "../hooks/use-images"

type ImgProps = {
	folder?: string
	name: string
	width?: string
}

const Img = (props: ImgProps) => {
	const imageData = useImages()
		, rename = props.name==`"The Catch"` ? `The Catch` : props.name
		, img
			= imageData.find(e => ((!props.folder && !e.relativeDirectory) || e.relativeDirectory == props.folder) && e.name == rename)
			|| imageData.find(e => e.name == `empty`)
	if(img) return <GatsbyImage image={getImage(img)} alt={img.name} style={{width: props.width}} />
	else return <div>Not Found</div>
}

export default Img
