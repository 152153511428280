/** @jsx jsx */
import React, { useState } from "react"
import { jsx, Flex } from "theme-ui"

type TabProps = {
	labels: string[]
	children: React.ReactNode[]
	defaultPos?: number
	safety?: boolean
	sxLabel?: any
	sxContent?: any
}

const block = {
	display: `block`,
	animation: `opacity01 .2s ease-in-out`,
}

const Tab = ({
	labels, children, defaultPos=0, safety=true, sxContent, sxLabel,
}: TabProps) => {
	const [pos, setPos] = useState(defaultPos)
	return (<>
		<Flex sx={{ mb: 3, }}>
			{labels.map((label,i) => (<React.Fragment key={i}>
				<div
					sx={{
						cursor: `pointer`,
						width: `100%`,
						textAlign: `center`,
						fontSize: 2,
						background: pos==i ? `accent` : `bga`,
						py: 1,
						borderBottom: `solid 1px`,
						borderColor: `border`,
						...sxLabel,
					}}
					onClick={()=>setPos(i)}
				>{label}</div>
			</React.Fragment>))}
		</Flex>
		{!safety ? <div sx={sxContent}>{children[pos]}</div> :
			<div sx={{
				...sxContent,
				"& > *": {
					display: `none`,
					":nth-of-type(1)": pos==0 ? block : null,
					":nth-of-type(2)": pos==1 ? block : null,
					":nth-of-type(3)": pos==2 ? block : null,
					":nth-of-type(4)": pos==3 ? block : null,
				},
			}}>{children}</div>
		}
	</>)
}

export default Tab

/*
		children.map((child,i)=>(<React.Fragment key={i}>
			{pos==i ? <Memo>{child}</Memo> : null}
		</React.Fragment>))

const Memo = React.memo(({children})=>(<>{children}</>))
*/
