/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Columns from "../components/columns"
import Tab from "../components/tab"
import Timer from "../components/@talent-materials-timer"
import Talent from "../components/@talent-materials-talent"

const zone = { Asia:"Etc/GMT-4", Europe:"Etc/GMT+3", America:"Etc/GMT+9", }

const Page = () => {
	const {t} = useTranslation()
		, storage = typeof window !== `undefined` ? localStorage.getItem(`server`) : null
		, server = storage ? storage : t(`server`)
		, serverTime = new Date().toLocaleString("en-US", {timeZone: zone[server]})
		, serverDow = new Date(serverTime).getDay()
		, pos = (serverDow-1)<0 ? 3 : (serverDow-1)%3
	return (
		<Layout title={t(`Talent Materials`)}>
			<Columns>
				<div>
					<Timer t={t} />
					<Tab
						name = "talent"
						labels = {[ t(`Mon Thu`), t(`Tue Fri`), t(`Wed Sat`), t(`Sun`) ]}
						defaultPos = {pos}
					>
						<Talent t={t} />
					</Tab>
				</div>
				<div>
				</div>
			</Columns>
		</Layout>
	)
}

export default Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["talent-materials", "items"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
