import { graphql, useStaticQuery } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"

type useImgStatic = {
	allFile: {
		nodes: {
			relativeDirectory: string
			name: string
			childImageSharp: {
				gatsbyImageData: IGatsbyImageData
			}
		}[]
	}
}

const useImages = (directory) => {
	const data = useStaticQuery<useImgStatic>(graphql`
		{
			allFile(filter: {sourceInstanceName: {eq: "images"}}) {
				nodes {
					relativeDirectory
					name
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
	`)
	return directory == null ? data.allFile.nodes :
		data.allFile.nodes.filter(e=>e.relativeDirectory == directory)
}

export default useImages
