/** @jsx jsx */
import { useEffect, useRef, useState } from "react"
import { jsx, Flex, Select } from "theme-ui"

const zone = { Asia:"Etc/GMT-4", Europe:"Etc/GMT+3", America:"Etc/GMT+9", }

const Timer = ({t}) => {
	const dowArr = useRef([ t(`Sunday`), t(`Monday`), t(`Tuesday`), t(`Wednesday`), t(`Thursday`), t(`Friday`), t(`Saturday`) ])
		, server = useRef()
		, serverDow = useRef(`　　　`)
		, serverHM = useRef(`　　　`)
		, [serverS, setServerS] = useState(`　`)
		, serverChange = () => {
			localStorage.setItem(`server`, server.current.value)
		}
		, func = () => {
			const serverTime = new Date().toLocaleString("en-US", {timeZone: zone[server.current.value]})
				, serverDate = new Date(serverTime)
				, dowStr = dowArr.current[serverDate.getDay()]
				, countDownMS = ( new Date(serverDate.getFullYear(),serverDate.getMonth(),serverDate.getDate()+1) ).getTime() - serverDate.getTime()
				, countSMH = [Math.floor(countDownMS/1000)%60, Math.floor(countDownMS/1000/60)%60, Math.floor(countDownMS/1000/60/60)]
			serverDow.current = dowStr
			serverHM.current = String(countSMH[2]).padStart(2,"0") +":"+ String(countSMH[1]).padStart(2,"0")
			setServerS( String(countSMH[0]).padStart(2,"0") )
		}
	
	useEffect(() => {
		const storage = localStorage.getItem(`server`)
		if(storage) server.current.value = storage
		func()
	},[])
	useEffect(() => {
		const timer = setInterval(func,1000)
		return () => {
			clearInterval(timer)
		}
	},[serverS])
	
	return (<div sx={{ display: `inline-block`, }}><Flex sx={{
		alignItems: `center`,
		borderBottom: `solid 1px`,
		borderColor: `border`,
		mb: `24px`,
	}}>
		<Select
			ref={server}
			onChange={serverChange}
			sx={{
				textAlign: `center`,
				border: 0,
			}}
			arrow={<div></div>}
			defaultValue={t(`server`)}
		>
			<option>Asia</option>
			<option>Europe</option>
			<option>America</option>
		</Select>
		<div sx={{ mr: 3, }}>{serverDow.current}</div>
		<div sx={{ mr: 3, }}>
			{serverHM.current}
			<span sx={{ fontSize: 1, ml: 1, }}>{serverS}</span>
		</div>
	</Flex></div>)
}

export default Timer
