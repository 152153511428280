import { graphql, useStaticQuery } from "gatsby"

import useTeamBuilder from "../hooks/use-team-builder"
import useStaticImages from "../hooks/use-static-images"

type Nodes = {
	nodes: {
		name: string
		star: number
		weapon: string
		element: string
		material: {
			boss: string
			specialty: string
			commonEnemy: string
			talent: string
			talentBoss: string
		}
		hoyowiki: number
	}[]
}
type UseCharactersProps = {
	allCharacters0Json: {
		Nodes
	}
	allCharacters4Json: {
		Nodes
	}
	allCharacters5Json: {
		Nodes
	}
}

const useCharacters = () => {
	const data = useStaticQuery<UseCharactersProps>(graphql`{
		allCharacters0Json {
			nodes {
				name
				star
				weapon
				element
				material {
					boss
					specialty
					commonEnemy
					talent
					talentBoss
				}
				hoyowiki
			}
		}
		allCharacters4Json {
			nodes {
				name
				star
				weapon
				element
				material {
					boss
					specialty
					commonEnemy
					talent
					talentBoss
				}
				hoyowiki
			}
		}
		allCharacters5Json {
			nodes {
				name
				star
				weapon
				element
				material {
					boss
					specialty
					commonEnemy
					talent
					talentBoss
				}
				hoyowiki
			}
		}
	}`)
	return (
		data.allCharacters5Json.nodes.concat(
			data.allCharacters4Json.nodes.concat(
				data.allCharacters0Json.nodes
			)
		)
	)
}

export default useCharacters

export const useList = (name) => {
	const characters = useCharacters()
		, teamBuilder = useTeamBuilder()
		, staticImages = useStaticImages()
	let list = []
	if(name == "teamBuilder") {
		list = teamBuilder.map(e=>e.name).filter(e=>e.slice(0,7)!==`Lumine_`)
	} else if(name == "fukafukafuka29") {
		const tmp = staticImages.filter(e=>e.relativeDirectory == name).map(e=>e.name)
		list = characters.filter(e=>{
			if(tmp.some(name=>name == e.name)) return true
			else return false
		}).map(e=>e.name)
	} else {
		list = characters.map(e=>e.name)
	}
	return list
}

export const useStar = (name) => {
	const character = useCharacters().find(e => e.name == name)
	return character ? character.star : 0
}
