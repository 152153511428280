/** @jsx jsx */
import * as React from "react"
import { jsx, Flex } from "theme-ui"

import Avatar from "./avatar"
import useCharacters from "../hooks/use-characters"

const nations = [`Mondstadt`,`Liyue`,`Inazuma`,`Sumeru`,]
const data = [
	[`Freedom`,`Resistance`,`Ballad`,],
	[`Prosperity`,`Diligence`,`Gold`,],
	[`Transience`,`Elegance`,`Light`,],
	[`Admonition`,`Ingenuity`,`Praxis`,],
]

const Talent = ({t}) => {
	const exchange = transpose(data)
		, characters = useCharacters()
		, dataSet = exchange.map(e => e.map(item => {
			const chara5 = characters.filter(c=>
					c.material && c.material.talent === item && c.star === 5
				).map(c=>c.name)
				, chara4 = characters.filter(c=>
					c.material && c.material.talent === item && c.star === 4
				).map(c=>c.name)
			return {
				item: item,
				chara5: chara5,
				chara4: chara4,
			}
		}))
	
	return (<>
		{dataSet.map((dow,i)=>(<React.Fragment key={i}><div>
			{dow.map((e,j)=>(<React.Fragment key={j}><Flex sx={{
				mx: `-3px`,
				mb: 3,
			}}>
				<div sx={{
					width: `calc(100% - 6px)`,
					p: `3px`
				}}>
					<Avatar folder="talent-material" name={e.item +`2`} star={2} text={t(e.item)} ratio={1.25} />
				</div>
				<div sx={{width: `250%`,}}>
				{(e.chara5.length < 5 && e.chara4.length < 5) ?<>
					<Flex>
						{e.chara5.map((name,k)=>(<React.Fragment key={k}>
							<div sx={{
								width: `calc(25% - 6px)`,
								m: `3px`,
							}}>
								<Avatar folder="character" name={name} star={5} />
							</div>
						</React.Fragment>))}
					</Flex>
					<Flex>
						{e.chara4.map((name,k)=>(<React.Fragment key={k}>
							<div sx={{
								width: `calc(25% - 6px)`,
								m: `3px`
							}}>
								<Avatar folder="character" name={name} star={4} />
							</div>
						</React.Fragment>))}
					</Flex>
				</>:<>
					<Flex sx={{flexWrap: `wrap`,}}>
						{e.chara5.concat(e.chara4).map((name,k)=>(<React.Fragment key={k}>
							<div sx={{
								width: `calc(25% - 6px)`,
								m: `3px`,
							}}>
								<Avatar folder="character" name={name} star={characters.find(e2=>e2.name === name).star} />
							</div>
						</React.Fragment>))}
					</Flex>
				</>}
				</div>
			</Flex></React.Fragment>))}
		</div></React.Fragment>))}
		<div>
			{data.map((nation,i)=>(<React.Fragment key={i}><div sx={{
				background: `bga`,
				borderRadius: `6px`,
			}}>
				<Flex sx={{
					"& > *": {
						width: `calc(100% / 4 - 8px)`,
						m: `4px`,
					}
				}}>
					<div>
						<Avatar folder="nation" name={nations[i]} star={1} />
					</div>
					{nation.map((e,j)=>(<React.Fragment key={j}><div>
						<Avatar folder="talent-material" name={e +`2`} star={2} />
					</div></React.Fragment>))}
				</Flex>
				<div sx={{
					textAlign: `center`,
					py: 1,
					mb: 3,
				}}>{t(nations[i])}</div>
			</div></React.Fragment>))}
		</div>
	</>)
}

export default Talent

const transpose = a => a[0].map((_, c) => a.map(r => r[c]))
