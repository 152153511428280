import { graphql, useStaticQuery } from "gatsby"

type UseTeamBuilderProps = {
	nodes: {
		name: string
		base: {
			main: number
			sub: number
			support: number
			healer: number
		}
		wl8: {
			main: number
			sub: number
			support: number
			healer: number
		}
		explor: number
		coop: {
			_0: string[]
			_1: string[]
			_2: string[]
			_3: string[]
			_4: string[]
		}
		team: {
			_0: string[][]
			_1: string[][]
			_2: string[][]
			_3: string[][]
			_4: string[][]
			_5: string[][]
			_6: string[][]
			_7: string[][]
		}
	}[]
}

const useTeamBuilder = () => {
	const data = useStaticQuery<UseTeamBuilderProps>(graphql`{
		allTeamBuilderJson {
			nodes {
				name
				base {
					main
					sub
					support
					healer
				}
				wl8 {
					main
					sub
					support
					healer
				}
				explor
				coop {
					_0
					_1
					_2
					_3
					_4
				}
				team {
					_0
					_1
					_2
					_3
					_4
					_5
					_6
					_7
				}
			}
		}
	}`)
	return data.allTeamBuilderJson.nodes
}

export default useTeamBuilder
